@import './App-css-variable.scss';

@font-face {
  font-family: 'Axiforma Bold';
  src: local('Axiforma Bold'), url('./fonts/Axiforma-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Axiforma Regular';
  src: local('Axiforma Regular'),
    url('./fonts/Axiforma-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Axiforma Bold Italic';
  src: local('Axiforma Bold Italic'),
    url('./fonts/Axiforma-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Axiforma Light';
  src: local('Axiforma Light'),
    url('./fonts/Axiforma-Light.woff') format('woff');
}
@font-face {
  font-family: 'Axiforma SemiBold';
  src: local('Axiforma SemiBold'),
    url('./fonts/Axiforma-SemiBold.woff') format('woff');
}

body {
  font-family: 'Axiforma Regular', sans-serif;
}

.loading-wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  position: fixed;
  align-items: center;
  justify-content: center;
}

.app-view--wrapper.dark {
  header {
    color: #fff;
    background-color: #1b1b1b !important;
  }
  .react-select-style {
    &__control {
      background-color: #666 !important;
    }

    &__single-value {
      color: #fff !important;
      font-size: 14px;
    }
    &__single-value--is-disabled {
      color: #888 !important;
    }
    &__control__single-value {
      color: #fff !important;
    }
    &__control--is-disabled {
      border-color: #222;
      background-color: #333 !important;
    }
    &__option {
      background-color: #fff !important;
      color: #444;
    }
    &__option:hover {
      background-color: #666 !important;
      color: #fff;
    }
  }

  input {
    //background-color: #666 !important;
  }
}

.app-view--wrapper.light {
  header {
    color: #444 !important;
    background-color: #fff !important;
  }
  .MuiAvatar-colorDefault {
    color: inherit;
    background-color: #e4e6eb;
  }
}

.MuiSkeleton-text {
  transform: none !important;
}

.MuiSkeleton-text {
  margin: 5px 0px !important;
}

.t-clamp-max-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.t-clamp-max-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.t-clamp-max-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

* {
  outline: none !important;
}

a {
  text-decoration: none;
}

[placeholder] {
  text-overflow: ellipsis;
}

.MuiPaper-root.MuiPaper-rounded {
  border-radius: 1rem;
}

// ul.MuiList-padding {
//   padding: 8px;
// }

.app-view--wrapper.xs {
  .xs-p-0 {
    padding: 0 !important;
  }
  .xs-p-0-x {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.normal-font {
  font-family: Axiforma Regular !important;
}
